<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="saveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm công thức tính điểm tuyển sinh' : 'Cập nhật công thức tính điểm tuyển sinh'"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên công thức <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên công thức"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetItem.name"
                  name="name"
                  placeholder="Tên công thức"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="code">
              <template v-slot:label>
                Mã công thức <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã công thức"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="targetItem.code"
                  name="code"
                  placeholder="Mã công thức"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="admissionRound">
              <template v-slot:label>
                Đợt tuyển sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Đợt tuyển sinh"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionRoundId"
                  :options="admissionRounds"
                  :reduce="option => option.value"
                  @input="readAdmissionForm"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="admissionFormId">
              <template v-slot:label>
                Phương thức tuyển sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Phương thức tuyển sinh"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionFormId"
                  :options="dataListAdmissionForm"
                  :reduce="option => option.value"
                  @input="selectAdmissionForm"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="admissionMajorId">
              <template v-slot:label>
                Ngành ứng tuyển
              </template>
              <validation-provider
                #default="{ errors }"
                name="Ngành ứng tuyển"
              >
                <v-select
                  v-model="targetItem.admissionMajorId"
                  :options="dataListMajorByAdmissionForm"
                  :reduce="option => option.value"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="typeFormula">
              <template v-slot:label>
                Hình thức tính điểm <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Hình thức tính điểm"
                rules="required"
              >
                <v-select
                  v-model="targetItem.typeFormula"
                  :options="typeFormulaOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="admissionScoreTypeId">
              <template v-slot:label>
                Loại điểm <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Loại điểm"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionScoreTypeId"
                  :options="dataAdmissionScores"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="addPriorityScore">
              <template v-slot:label>
                Cộng điểm ưu tiên vào tổng điểm tuyển sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Cộng điểm ưu tiên vào tổng điểm tuyển sinh"
                rules="required"
              >
                <v-select
                  v-model="targetItem.addPriorityScore"
                  :options="addPriorityScoreOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="description">
              <template v-slot:label>
                Mô tả
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mô tả"
              >
                <b-form-textarea
                  id="description"
                  v-model="targetItem.description"
                  name="description"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetItem.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('saveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import { Flag } from '@/const/flag'
import { ELIGIBLE_STATUSES, TYPE_FORMULAS } from '@/const/status'

export default {
  name: 'Save',
  components: {
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        name: '',
        code: '',
        admissionRoundId: null,
        admissionFormId: null,
        admissionMajorId: null,
        admissionScoreTypeId: null,
        typeFormula: null,
        description: null,
        addPriorityScore: null,
        status: Flag.ACTIVE,
        orderNo: 1,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'admissionCommittee/statuses',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataListAdmissionForm: 'admissionForm/admissionFormByAdmissionRoundId',
      dataListMajorByAdmissionForm: 'admissionMajor/dataListMajorByAdmissionForm',
      dataAdmissionScores: 'factAdmissionScoreType/admissionScoreTypes'
    }),
    statusOptions() {
      return this.statuses
    },
    typeFormulaOptions() {
      return TYPE_FORMULAS
    },
    addPriorityScoreOptions() {
      return ELIGIBLE_STATUSES
    },
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      createFormulaAdmissionScore: 'formulaAdmissionScore/create',
      updateFormulaAdmissionScore: 'formulaAdmissionScore/update',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
      readAdmissionFormByAdmissionRoundId: 'admissionForm/readAdmissionFormByAdmissionRoundId',
      getMajorByAdmissionFormId: 'admissionMajor/getMajorByAdmissionFormId',
      getAllAdmissionScores: 'factAdmissionScoreType/getAdmissionScoreTypes'
    }),
    async readAdmissionForm(id) {
      await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: id })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async selectAdmissionForm() {
      await this.getMajorByAdmissionFormId({ admissionFormId: this.targetItem.admissionFormId })
    },
    async onShow() {
      await this.getAdmissionRoundsByOrganization({ organizationId: getUser().orgId })
      await this.getAllAdmissionScores()
      if (this.item) {
        this.targetItem = { ...this.item }
        await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: this.targetItem.admissionRoundId })
        await this.getMajorByAdmissionFormId({ admissionFormId: this.targetItem.admissionFormId })
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        name: '',
        code: '',
        admissionRoundId: null,
        admissionFormId: null,
        admissionMajorId: null,
        admissionScoreTypeId: null,
        typeFormula: null,
        description: null,
        addPriorityScore: null,
        status: Flag.ACTIVE,
        orderNo: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createFormulaAdmissionScore(this.targetItem)
            : await this.updateFormulaAdmissionScore(this.targetItem)
          if (response) {
            const {
              isSuccessful,
              message
            } = response
            if ( isSuccessful ) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if ( type === 'hide' ) {
                this.$bvModal.hide('saveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch ( e ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<template>
  <validation-observer
    ref="formulaAdmissionScoreCoefficientSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="formulaAdmissionScoreCoefficientSaveModal"
      body-class="position-static"
      centered
      :title="isUpdated ? 'Cập nhật môn xét điểm & hệ số' : 'Thêm môn xét điểm & hệ số'"
      :no-close-on-backdrop="true"
      @shown="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="admissionSubjectId">
          <template v-slot:label>
            Môn học <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Môn học"
            rules="required"
          >
            <v-select
              id="admissionSubjectId"
              v-model="targetItem.admissionSubjectId"
              label="label"
              :options="dataListSubject"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="coefficient">
          <template v-slot:label>
            Hệ số <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Hệ số"
            rules="required"
          >
            <b-form-input
              id="coefficient"
              v-model.trim="targetItem.coefficient"
              name="coefficient"
              type="number"
              :state="getElementState(errors)"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetItem.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-if="!isUpdated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('formulaAdmissionScoreCoefficientSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { STATUSES } from '@/const/status'
import { required } from '@core/utils/validations/validations'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Flag } from '@/const/flag'

export default {
  name: 'FormulaAdmissionScoreCoefficientSave',
  directives: {
    Ripple,
  },
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BModal,
    BOverlay,
    vSelect,
    BFormInvalidFeedback,
  },
  props: {
    formulaAdmissionScoreId: {
      type: Number,
      default: null,
    },
    formulaAdmissionScoreCoefficient: {
      type: Object,
      default: () => undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        id: null,
        formulaAdmissionScoreId: null,
        admissionSubjectId: null,
        coefficient: null,
        status: Flag.ACTIVE,
        orderNo: 1,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      dataListSubject: 'factAdmissionSubject/dataListSubject',
    }),
    statusOptions() {
      return STATUSES
    },
    isUpdated() {
      return !!this.formulaAdmissionScoreCoefficient
    },
  },
  methods: {
    ...mapActions({
      getListSubject: 'factAdmissionSubject/getListSubject',
      createFormulaAdmissionScoreCoefficient: 'formulaAdmissionScoreCoefficient/create',
      updateFormulaAdmissionScoreCoefficient: 'formulaAdmissionScoreCoefficient/update',
    }),
    async onShow() {
      if (this.formulaAdmissionScoreCoefficient) {
        this.targetItem = { ...this.formulaAdmissionScoreCoefficient }
      }
      this.isLoading = true
      try {
        await Promise.all([
          this.getListSubject(),
        ])
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .formulaAdmissionScoreCoefficientSaveFormRef
        .reset()
      this.targetItem = {
        id: null,
        formulaAdmissionScoreId: null,
        admissionSubjectId: null,
        coefficient: null,
        status: Flag.ACTIVE,
        orderNo: 1,
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onSave(type = null) {
      const valid = this.$refs
        .formulaAdmissionScoreCoefficientSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isUpdated
            ? await this.updateFormulaAdmissionScoreCoefficient({
              id: this.targetItem.id,
              formulaAdmissionScoreId: this.formulaAdmissionScoreId,
              admissionSubjectId: this.targetItem.admissionSubjectId,
              coefficient: this.targetItem.coefficient,
              status: this.targetItem.status,
              orderNo: this.targetItem.orderNo,
            })
            : await this.createFormulaAdmissionScoreCoefficient({
              formulaAdmissionScoreId: this.formulaAdmissionScoreId,
              admissionSubjectId: this.targetItem.admissionSubjectId,
              coefficient: this.targetItem.coefficient,
              status: this.targetItem.status,
              orderNo: this.targetItem.orderNo,
            })
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('formulaAdmissionScoreCoefficientSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
